
.book{
  max-width: 768px;
  margin: 0px auto;

  .page {
    padding: 27px;

    p {
      text-indent: 0px;
    }

    > p.ql-align-justify {
      text-indent: 0px;
    }

    p.ql-align-justify ~ p.ql-align-justify {
      text-indent: 18px;
    }

  }
}




@media screen and (orientation: landscape) {

  .book {
    margin: 0px 15px;
  }

  @media (min-width: 600px){

    .book{

      .page {
        padding: 45px;
      }
    }
  }

  @media (min-width: 798px){

    .book{

      .page {
        padding: 60px;
      }

      margin: 0px auto;
    }
  }



}

@media screen and (orientation: portrait) {

  .book {
    margin: 0px auto;
  }


  @media (min-width: 600px){

    .book{
      margin: 15px;

      .page {
        padding: 45px;
      }
    }
  }


  @media (min-width: 1024px){

    .book{
        margin: 0px auto;

      .page {
        padding: 60px;
      }
    }
  }
}
