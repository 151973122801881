/* You can add global styles to this file, and also import other style files */
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/layout';
@import 'styles/constants';
@import 'quill/dist/quill.snow.css';
@import 'swiper/scss'; // to be ignored in intellij for version 8.4.7 correct styles are created

body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #efefef;
}

button {

  color: black;

  border-radius: 9px;

  font-size: 12px;

}

a.link-button {
  display: inline-block;
  box-sizing: border-box;
  padding: 22px 24px !important;
}

a.link-button,
button {
  padding: 9px 15px;
  border: none;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 3px 3px -3px #00000040;
  font-family: monospace;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  font-size: 18px;
  color: black;
  &.disabled {
    color: ghostwhite !important;
  }
  &.big{
    width: 63px;
    height: 63px;
  }
}

.page {
  background-color: $paper-white;
  width: 100%;
  aspect-ratio: 1 / 1.414;
  padding: 45px;
  box-sizing: border-box;

  h2,h3{
    font-family: 'Roboto';
  }

  .ql-align-center {
    text-align: center;
  }

  p {
    margin: 0.2em;
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;

    span {
      &.ql-size-huge {
        font-size: 27px;
      }
    }

    &.ql-align-justify {
      text-align: justify;
    }
  }
}

/*@keyframes bookmark_animation
{
  0% {transform: rotate(0deg);top:85px;}
  25% {transform: rotate(20deg);left:0px;}
  50% {transform: rotate(0deg);left:500px;}
  55% {transform: rotate(0deg);left:500px;}
  70% {transform: rotate(0deg);left:500px;background:#1ec7e6;}
  100% {transform: rotate(-360deg);left:0px;}
}*/
@keyframes bookmark_animation
{
 // 5% {opacity: 0.5;}
 // 10% {opacity: 0.75;}
  65% {opacity: 1.0; top: 90px;}
  100% {opacity: 0.5; top: -150px;}
}

@keyframes bookmark {
  0%   {background-color:red !important; left:0px; top:0px;}
  25%  {background-color:yellow !important; left:0px; top:0px;}
  50%  {background-color:blue !important; left:0px; top:0px;}
  75%  {background-color:green; left:0px; top:0px;}
  100% {background-color:red; left:0px; top:0px;}
}



span.check-icon {
  font-size: 21px;
  border-radius: 50%;
  background-color: white;
  color: #10bd10;
}
