
.fixed {
  position: fixed;
}

.flex {
  display: flex;

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-center {
    align-items: center;
  }

  &.flex-start {
    justify-content: flex-start;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.row {
    flex-direction: row;
  }

  & column {
    flex-direction: column;
  }
}
